<template lang="pug">
  div.logs-tab
    v-tabs(fixed-tabs v-model="tab" small).log-tab-main
      v-tab(@click="loadAllPages" small) All
      v-tab(@click="loadTOP" small) TOP
      v-tab(@click="loadMIJN" small) MIJN
</template>

<script>
import { LOG_PAGE } from "@/util/const"

export default {
  props: {
    model: {
      type: Object,
      required: true
    },
    loadData: {
      type: Function,
      required: true
    },
    getDefaultPaginationParams: {
      type: Function,
      required: true
    }
  },

  data: () => ({
    tab: null
  }),

  methods: {
    loadAllPages() {
      this.model.params.page = [LOG_PAGE.TOP_CBR_SLOTS, LOG_PAGE.MIJN_CBR_SLOTS]
      this._loadDataHandler()
    },

    _loadDataHandler() {
      this.model.items = []
      this.loadData({...this.getDefaultPaginationParams()})
    },


    loadTOP() {
      this.model.params.page = [LOG_PAGE.TOP_CBR_SLOTS]
      this._loadDataHandler()
    },

    loadMIJN() {
      this.model.params.page = [LOG_PAGE.MIJN_CBR_SLOTS]
      this._loadDataHandler()
    },

    resetStatus() {
      delete this.model.params.status
      this._loadDataHandler()
    }
  }
}
</script>

<style lang="scss">
.logs-tab .v-tabs .v-item-group {
  background-color: $log-primary;
}

.log-tab-main.v-tabs .v-item-group .v-tab.v-tab {
  color: #fff;
}

.log-tab-secondary.v-tabs .v-item-group .v-tab.v-tab {
  color: $log-default;

  &.v-tab--active {
    color: $primary-color;
  }
}
</style>